import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signupCodeCheck } from '../../apiControllers/signupCodeApiController';  // added for signup code
import "../Styles/Signup.css";

const SignupCodeDialog = () => {
  const [signupCode, setSignupCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateCodeFormat = (code) => {
    const codeRegex = /^[A-Z0-9]{6}$/;
    return codeRegex.test(code);
  };

  const handleSubmit = async () => {
    if (!validateCodeFormat(signupCode)) {
      setError('Code must be 6 characters long, alphanumeric, and uppercase.');
      return;
    }
    
    try {
      const response = await signupCodeCheck(signupCode);
      const codeData = response.data;
      
      if (!codeData.is_used) {
        navigate('/signup', { 
          state: { signupCode: codeData.signup_code } 
        });
      } else {
        setError('This code has already been used.');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('Invalid signup code.');
      } else {
        setError('An error occurred. Please try again. Error:', error);
      }
    }
  };

  const handleBack = () => {
    navigate('/login');
  };

  return (
    <div className="signup-container">
      <div className="signup-form-box">
        <span className="signup-header">Enter Signup Code</span>
        <form className="signup-form" onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            value={signupCode}
            onChange={(e) => setSignupCode(e.target.value.toUpperCase())}
            placeholder="Enter Code"
            className="signup-input-field"
            maxLength={6}
          />
          {error && <span className="signup-error-text">{error}</span>}
          <div className="signup-button-group">
            <button type="button" className="signup-btn" onClick={handleBack}>
              Back
            </button>
            <button type="button" className="signup-signup-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
        <span className="signup-footer">
          Email <a href="mailto:support@ofstaff.io">support@ofstaff.io</a> for
          assistance.
        </span>
      </div>
    </div>
  );
};

export default SignupCodeDialog;