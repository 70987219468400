/**
 * This module is for managing API calls to the server's signup code endpoints.
 */
import api from "./axios";

/**
 * Checks whether a signup code is valid.
 *
 * @param {string} signupCode - The signup code.
 * @returns {Object} - The response data from the signup code check.
 * @throws {Error} - If there's an error during signup code check.
 */
export const signupCodeCheck = async (signupCode) => {
  try {
    const response = await api.get(`/v1/signup_code/single/${signupCode}`);
    
    return response;
  } catch (error) {
    console.error("Error during signup code check:", error);
    throw error;
  }
}

/**
 * Updates a signup code by assigning it a user id.
 * 
 * @param {string} signupCode - The signup code.
 * @param {string} user_id - The user id.
 * @returns {Object} - The response data from the assignment.
 * @throws {Error} - If there's an error during assignment.
 */
export const signupCodeAssignment = async(signupCode, user_id) => {
  try {
      await api.put('/v1/signup_code/single', {
      signup_code: signupCode,
      user_id: user_id
      });
  } catch (error) {
      console.error("Error updating signup code:", error);
      throw error;
  }
};
