import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { login } from "../../apiControllers/authController";
import "../Styles/Login.css";
import { Link } from "react-router-dom";

/**
 * Login component that handles user authentication.
 *
 * @component
 * @example
 * return (
 *   <Login />
 * )
 */
function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [signupSuccess, setSignupSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Effect hook to check for signup success query parameter.
   */
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (query.get("signup") === "success") {
      setSignupSuccess(true);
    }
  }, [location.search]);

  /**
   * Handles input changes and updates the form data state.
   *
   * @param {Object} e - The input change event.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "email" ? value.toLowerCase() : value,
    });
  };

  /**
   * Validates the login form data.
   *
   * @returns {Object} - An object containing any validation errors.
   */
  const validateForm = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(formData.password)) {
        newErrors.password =
          "Password must be at least 8 characters long, contain at least one letter, one number, and one special character";
      }
    }
    return newErrors;
  };

  /**
   * Handles form submission, performs login, and navigates based on the user's onboarding status.
   */
  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      try {
        const response = await login({
          email_address: formData.email,
          password: formData.password,
        });
        if (response.user.onboarding_complete) {
          navigate("/dashboard", { state: { user: response.user } });
        } else {
          navigate("/OnBoarding/step-one", { state: { user: response.user } });
        }
      } catch (error) {
        console.error("Error during login", error);
        setErrors({ api: "Invalid email or password." });
      }
    }
  };

  /**
   * Redirects the user to the signup page.
   */
  const handleSignupRedirect = () => {
    // navigate("/signup");
    navigate("/signup-code");  // commented out above & added for signup code
  };

  return (
    <div className="login-container">
      <div className="login-form-box">
        <span className="login-header">Welcome back!</span>
        {signupSuccess && (
          <div className="login-success-message">
            Signup successful! Please log in.
          </div>
        )}
        <form className="login-form" onSubmit={(e) => e.preventDefault()}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="login-input-field"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && (
            <span className="login-error-text">{errors.email}</span>
          )}

          <input
            type="password"
            name="password"
            placeholder="Password"
            className="login-input-field"
            value={formData.password}
            onChange={handleInputChange}
          />
          {errors.password && (
            <span className="login-error-text">{errors.password}</span>
          )}

          <div className="login-reset-password">
            <Link className="login-reset-password-text" to="/reset-password">
              <span>Forgot your password?</span>
            </Link>
          </div>

          <div className="login-button-group">
            <button type="button" className="login-btn" onClick={handleSubmit}>
              Log in
            </button>
            <button
              type="button"
              className="login-signup-btn"
              onClick={handleSignupRedirect}
            >
              Sign up
            </button>
          </div>
        </form>
        {errors.api && <span className="login-error-text">{errors.api}</span>}
        <span className="login-footer">
          Email <a href="mailto:support@ofstaff.io">support@ofstaff.io</a> for
          assistance.
        </span>
      </div>
    </div>
  );
}

export default Login;
