import React, {useEffect} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {getToken, isTokenExpired} from "./apiControllers/authController";

import Cookies from "js-cookie";
import AccountSettings from "./components/AccountSettings/AccountSettings";
import AskStaffy from "./components/AskStaffy/AskStaffy";
import Dashboard from "./components/Dashboard/Dashboard";
import FBReport from "./components/FBReport/FBReport";
import Feedback from "./components/Feedback/Feedback";
import Goals from "./components/Goals/Goals";
import GrowthLibrary from "./components/GrowthLibrary/GrowthLibrary";
import Insights from "./components/Insights/Insights";
import Performance from "./components/Performance/Performance";
import Login from "./components/Login/Login";
import StepOne from "./components/OnBoarding/StepOne";
import StepThree from "./components/OnBoarding/StepThree";
import StepTwo from "./components/OnBoarding/StepTwo";
import PeerFeedback from "./components/PeerReview/PeerFeedback";
import PeerReview from "./components/PeerReview/PeerReview";
import SelfAssessment from "./components/SelfAssessment/SelfAssessment";
import SelfAssessmentInProgress from "./components/SelfAssessment/SelfAssessmentInProgress";
import Sidebar from "./components/SideBar/sidebar";
import Signup from "./components/Signup/Signup";
import PeerFeedbackInstructions from "./components/PeerReview/PeerFeedbackInstructions";
import PasswordReset from "./components/PasswordReset/PasswordReset";
import LinkSent from "./components/PasswordReset/LinkSent";
import PasswordResetEmailLink from "./components/PasswordReset/PasswordResetEmailLink";
import PasswordResetSuccess from "./components/PasswordReset/PasswordResetSuccess";
import GoalsBackground from "./components/Goals/GoalsBackground";
import SignupCodeDialog from './components/Signup/SignupCodeDialog';  // added this and Route path="/signup-code" below for signup code


/**
 * App component for rendering the main application.
 *
 * @component
 * @example
 * return (
 *   <App />
 * )
 */
function App() {
    const location = useLocation();
    const isAuthPage =
        location.pathname === "/login" ||
        location.pathname === "/signup" ||
        location.pathname === "/signup-code" ||  // added for signup code - prevents redirect, authorizes user to be there
        location.pathname.includes("/test") ||
        location.pathname.includes("/peer-review") ||
        location.pathname.includes("/reset-password");

    // For testing
    const isTestingPage = location.pathname.includes("/test");

    const userToken = getToken();

    useEffect(() => {
        if (isTokenExpired(userToken) && !isAuthPage) {
            Cookies.remove("userToken");
            window.location.href = "/login";
        }
    }, [userToken, isAuthPage]);

    if (!userToken && !isAuthPage) {
        // Redirect to login if user is not authenticated
        return <Navigate to="/login"/>;
    }

    return (
        <div className={`app-container ${isAuthPage ? "auth-page" : ""}`}>
            {!isAuthPage && !isTestingPage && <Sidebar/>}
            <div className={`app-main-content ${isAuthPage ? "full-width" : ""}`}>
                <Routes>
                    {/* Login routes */}
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup-code" element={<SignupCodeDialog />} />
                    <Route path="/signup" element={<Signup/>}/>
                    <Route
                        path="/reset-password"
                        element={<PasswordReset></PasswordReset>}
                    />
                    <Route
                        path="/reset-password/link-sent"
                        element={<LinkSent></LinkSent>}
                    />
                    <Route
                        path="/reset-password/email-link"
                        element={<PasswordResetEmailLink></PasswordResetEmailLink>}
                    />
                    <Route
                        path="/reset-password/email-link/success"
                        element={<PasswordResetSuccess></PasswordResetSuccess>}
                    ></Route>
                    {/* App routes */}
                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/insights" element={<Insights/>}/>
                    <Route path="/account-settings" element={<AccountSettings/>}/>
                    <Route path="/goals" element={<Goals/>}/>
                    <Route path="/goals/:goalId" element={<GoalsBackground/>}/>
                    <Route path="/feedback" element={<Feedback/>}/>
                    <Route path="/feedback/report" element={<FBReport/>}/>
                    <Route path="/growth-library" element={<GrowthLibrary/>}/>
                    <Route path="/ask-staffy" element={<AskStaffy/>}/>
                    <Route path="/OnBoarding/step-one" element={<StepOne/>}/>
                    <Route path="/OnBoarding/step-two" element={<StepTwo/>}/>
                    <Route path="/OnBoarding/step-three" element={<StepThree/>}/>
                    <Route path="/self-assessment" element={<SelfAssessment/>}/>
                    <Route
                        path="/self-assessment-survey"
                        element={<SelfAssessmentInProgress/>}
                    />
                    <Route
                        path="/peer-review"
                        element={<PeerReview></PeerReview>}
                    ></Route>
                    <Route
                        path="/peer-review/instructions"
                        element={<PeerFeedbackInstructions></PeerFeedbackInstructions>}
                    ></Route>
                    <Route
                        path="/peer-review/feedback"
                        element={<PeerFeedback></PeerFeedback>}
                    ></Route>
                </Routes>
            </div>
        </div>
    );
}

export default App;
