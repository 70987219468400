import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";  // added useLocation for signup code
import { signupWithCode } from "../../apiControllers/authController";  // added for signup code
import "../Styles/Signup.css";

/**
 * Signup component that handles user registration.
 *
 * @component
 * @example
 * return (
 *   <Signup />
 * )
 */
function Signup() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();  // added for signup code
  const signupCode = location.state?.signupCode;  // added for signup code

  /**
   * Handles input changes and updates the form data state.
   *
   * @param {Object} e - The input change event.
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "email" ? value.toLowerCase() : value,
    });
  };

  /**
   * Validates the signup form data.
   *
   * @returns {Object} - An object containing any validation errors.
   */
  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First name is required";
    if (!formData.last_name) newErrors.last_name = "Last name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = "Email must be a valid email address";
      }
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else {
      const passwordRegex =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(formData.password)) {
        newErrors.password = (
          <>
            <span>Password must be at least 8 characters long,</span>
            <br />
            <span>contain at least one letter, one number,</span>
            <br />
            <span>and one special character</span>
          </>
        );
      }
    }
    return newErrors;
  };

  /**
   * Handles form submission, performs signup, and navigates to login page on success.
   */
  const handleSubmit = async () => {
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      try {
        const userData = {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email_address: formData.email,
          password: formData.password,
        };
        const response = await signupWithCode(userData, signupCode);

        navigate("/login?signup=success");
      } catch (error) {
        console.error("Error during signup", error);
        if (error.response && error.response.status === 409) { // 409 if email already in use
          setErrors({
            email: "Email already exists. Please use a different email.",
          });
        } else {
          setErrors({ api: "Failed to sign up. Please try again later." });
        }
      }
    }
  };

  /**
   * Navigates to the previous page.
   */
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="signup-container">
      <div className="signup-form-box">
        <span className="signup-header">Let's get started!</span>
        <form className="signup-form" onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            className="signup-input-field"
            value={formData.first_name}
            onChange={handleInputChange}
          />
          {errors.first_name && (
            <span className="signup-error-text">{errors.first_name}</span>
          )}

          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            className="signup-input-field"
            value={formData.last_name}
            onChange={handleInputChange}
          />
          {errors.last_name && (
            <span className="signup-error-text">{errors.last_name}</span>
          )}

          <input
            type="email"
            name="email"
            placeholder="Email"
            className="signup-input-field"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && (
            <span className="signup-error-text">{errors.email}</span>
          )}

          <input
            type="password"
            name="password"
            placeholder="Password"
            className="signup-input-field"
            value={formData.password}
            onChange={handleInputChange}
          />
          {errors.password && (
            <span className="signup-error-text">{errors.password}</span>
          )}

          <div className="signup-button-group">
            <button type="button" className="signup-btn" onClick={handleBack}>
              Back
            </button>
            <button
              type="button"
              className="signup-signup-btn"
              onClick={handleSubmit}
            >
              Sign up
            </button>
          </div>
        </form>
        {errors.api && <span className="signup-error-text">{errors.api}</span>}
        <span className="signup-footer">
          Email <a href="mailto:support@ofstaff.io">support@ofstaff.io</a> for
          assistance.
        </span>
      </div>
    </div>
  );
}

export default Signup;
